<template>
  <b-card-code
    no-body
  >
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      :title="`${recordToDelete.isPermanent ? 'Are you sure you want to permanently delete this record?' : 'Are you sure you want to delete this record?'}`"
      centered
      ok-title="Delete"
      @ok="deleteModalHandler"
    >
      <div>
        {{ recordToDelete.isPermanent ? 'Permanently deleted records cannot be recovered later.' : 'In case you might want to recover it later, you can enable the "With deleted" toggle to find it and either recover or permanently delete it.' }}
      </div>
    </b-modal>
    <b-modal
      v-if="!isLoading"
      id="modal-edit"
      ref="modal"
      title="Edit Companies?"
      hide-footer
      size="md"
      @hidden="closeModal"
    >
      <p>Are you sure that you want to change status in {{ $refs.VueGoodTable.selectedRows.length }} items?</p>
      <div class="d-flex justify-content-between align-items-center">
        <b-button
          variant="primary"
          @click="submitStatusesChanging(1)"
        >
          Change to active
        </b-button>
        <b-button
          variant="primary"
          @click="submitStatusesChanging(0)"
        >
          Change to inactive
        </b-button>
      </div>
    </b-modal>
    <b-modal
      v-if="!isLoading"
      id="modal-delete"
      ref="modalDeleteBulk"
      title="Delete Companies?"
      hide-footer
      size="md"
      @hidden="closeModal"
    >
      <p>Are you sure that you want to delete {{ $refs.VueGoodTable.selectedRows.length }} companies?</p>
      <div class="d-flex justify-content-between align-items-center">
        <b-button
          variant="primary"
          @click="submitDeleteBulk(1)"
        >
          Yes
        </b-button>
        <b-button
          variant="secondary"
          @click="submitDeleteBulk(0)"
        >
          Cancel
        </b-button>
      </div>
    </b-modal>
    <b-card-body>
      <b-col
        v-if="$can('create', 'companies')"
        order-md="2"
        order="1"
        class="text-right"
      >
        <div
          v-if="$can('delete', 'companies')"
          label-class="label-class"
          class="with-deleted-checkbox"
        >
          <span>With deleted</span>
          <b-form-checkbox
            class="mr-0"
            switch
            inline
            @change="setFilterWithTrashed"
          />
        </div>
        <b-button
          v-if="$can('additional-action-delete', 'companies')"
          variant="primary"
          class="mr-2"
          @click="toggleFilterOnlyTrashed"
        >
          <span v-if="this.onlyTrashed">Without deleted</span>
          <span v-else>Only deleted</span>
        </b-button>
        <b-button
          to="/company-management/create"
          variant="primary"
        >
          Create
        </b-button>
      </b-col>
      <b-form @submit.prevent="searchRows()">
        <p v-if="companyId || companyName">
          Company: <a
            :href="`/company-management/summary/${companyId}`"
          >
            {{ companyName }}
          </a>
        </p>
        <b-form-row class="align-items-end">
          <b-col md="1">
            <feather-icon
              v-if="(searchFields && searchFields.length > 0) || (orderBy && orderBy.length > 0) || (sortedBy && sortedBy.length > 0)"
              v-b-tooltip.hover
              icon="XIcon"
              title="Reset filters"
              size="24"
              class="text-body align-middle mr-25"
              @click="handleReset"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-card-body>
    <table-mobile-solo
      :search="searchValue"
      :handle-search="handleMobileSearch"
      :mobile-search-fields="mobileSearchFields"
      :loading="isLoading"
      :items="items"
      :fields="fields"
      :pagination="{
        pagination: pagination,
        perPage: perPage,
        currentPage: page,
        pageSizeOptions: pageSizeOptions,
        enabled: true,
        perPageChanged: perPageChanged,
        changePage: changePage,
      }"
      :rules="{
        country(value) {
          return value.addresses.country ? value.addresses.country.name : '-'
        },
        type(value) {
          return value.type ? tableTypes.find((type) => type.value == value.type.id).text : '-'
        },
        city(value) {
          return value.addresses ? value.addresses.city : '-'
        },
        state(value) {
          return value.addresses ? value.addresses.state : '-'
        },
        zipCode(value) {
          return value.addresses ? value.addresses.postcode : '-'
        },
      }"
      :key-value="'name'"
      :key-prop="'id'"
      :actions="[{
        label: 'Show info',
        type: 'primary',
        icon: 'EyeIcon',
        visible: () => $can('view', 'companies'),
        click: ({id}) => redirectToSummary(id),
      }, {
        label: 'Edit',
        type: 'primary',
        icon: 'Edit2Icon',
        visible: () => $can('update', 'companies'),
        click: ({id}) => toEdit(id),
      },{
        label: 'Delete',
        type: 'danger',
        icon: 'TrashIcon',
        visible: ({is_deleted}) => $can('delete', 'companies') && !is_deleted,
        click: ({id}) => deleteHandler(id),
      }, {
        label: 'Permanently delete',
        type: 'danger',
        icon: 'TrashIcon',
        visible: ({is_deleted}) => $can('delete', 'companies') && is_deleted,
        click: ({id}) => deleteHandler(id, true),
      }, {
        label: 'Recover',
        type: 'primary',
        icon: 'RefreshCcwIcon',
        visible: ({is_deleted}) => $can('delete', 'companies') && is_deleted,
        click: ({id}) => recoverRow(id),
      }]"
    />

    <b-card-body>
      <!-- table -->
      <vue-good-table
        ref="VueGoodTable"
        :is-loading="isLoading"
        :columns="fields"
        :rows="items"
        compact-mode
        :total-rows="pagination.total"
        class="company-table hide-mobile-table"
        :row-style-class="rowStyleClassFn"
        mode="remote"
        :sort-options="{
          enabled: true,
          multipleColumns: true,
          initialSortBy: {field: orderBy, type: sortedBy}
        }"
        :pagination-options="{
          enabled: true,
          perPage: perPage,
          setCurrentPage: page
        }"
        :select-options="{
          enabled: true,
          disableSelectInfo: false,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'table-selected-row-info-panel',
        }"
        @on-sort-change="onSortChange"
        @on-column-filter="columnFilterFn"
      >
        <template slot="selected-row-actions">
          <b-button
            v-b-modal.modal-edit
            variant="primary"
            class="ml-auto"
          >
            Change statuses
          </b-button>
          <b-button
            v-b-modal.modal-delete
            variant="primary"
            class="ml-1"
          >
            Bulk Delete
          </b-button>
        </template>
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="$can('view', 'companies')"
                  @click="redirectToSummary(props.row.id)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                  <span>Show info</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('update', 'companies')"
                  @click.prevent="toEdit(props.row.id)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('delete', 'companies') && props.formattedRow['is_active'] === 'Activated' && !props.row.is_deleted"
                  v-b-tooltip.hover
                  title="You can't delete active company"
                  disabled
                  @click.prevent="deleteHandler(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-else-if="$can('delete', 'companies') && props.formattedRow['is_active'] !== 'Activated' && !props.row.is_deleted"
                  @click.prevent="deleteHandler(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="$can('delete', 'companies') && props.formattedRow['is_active'] !== 'Activated' && props.row.is_deleted"
                  @click.prevent="deleteHandler(props.row.id, true)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Permanently delete</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('delete', 'companies') && props.formattedRow['is_active'] !== 'Activated' && props.row.is_deleted"
                  @click.prevent="recoverRow(props.row.id)"
                >
                  <feather-icon
                    icon="RefreshCcwIcon"
                    class="mr-50"
                  />
                  <span>Recover</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else-if="props.column.field === 'type.id'">
            <span
              v-for="item in tableTypes"
              :key="item.value"
            >
              <template v-if="item.value === props.formattedRow[props.column.field]"> {{ item.text }}</template>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'name'"
          >
            <router-link
              :to="`/company-management/summary/${props.row.id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="perPage"
                :options="pageSizeOptions"
                class="mx-1"
                @change="perPageChanged"
              />
              <span class="text-nowrap"> of {{ pagination.total }} entries </span>
            </div>
            <div>
              <b-pagination
                v-if="pagination.last_page !== 1"
                :value="page"
                :total-rows="pagination.total"
                :per-page="perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>

    <b-modal
      id="modalSoftDelete"
      ref="modalSoftDelete"
      :title="deleteErrorPopUpInfo.title"
      centered
      ok-only
      ok-variant="secondary"
      ok-title="Cancel"
    >
      <p v-html="deleteErrorPopUpInfo.content" />
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCardBody,
  BPagination,
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BForm,
  BFormRow,
  BSpinner,
  BModal,
  VBTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import IndexPagesMixin from '@/mixins/IndexPages.vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, watch } from '@vue/composition-api'
import TableMobileSolo from '@/components/TableMobileSolo.vue'
import axios from '../axios-resolver'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormCheckbox,
    BCardCode,
    BCardBody,
    BButton,
    BPagination,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BForm,
    BFormRow,
    BSpinner,
    BModal,
    VueGoodTable,
    TableMobileSolo,
  },
  mixins: [
    IndexPagesMixin,
  ],
  data() {
    return {
      searchValue: '',

      mobileSearchFields: {
        name: '',
        email: '',
      },
      isLoading: true,
      uppdateRouteName: 'company-management-update',
      searchString: 'is_active:1',
      searchFieldsString: 'is_active',
      currentRoute: this.$route.path,

      deleteErrorPopUpInfo: {},

      recordToDelete: {
        id: null,
        isPermanent: false,
      },

      fields: [
        {
          field: 'name',
          label: 'Company name',
          filterOptions: {
            enabled: true,
            placeholder: 'Company name',
            filterValue: '',
          },
        },
        {
          field: 'type.id',
          mobileSpecial: 'type',
          label: 'Type',
          filterOptions: {
            enabled: true,
            placeholder: 'Type',
            filterValue: '',
          },
        },
        {
          field: 'addresses.city',
          mobileSpecial: 'city',
          label: 'Town/city',
          filterOptions: {
            enabled: true,
            placeholder: 'Town/city',
            filterValue: '',
          },
          sortable: false,
        },
        {
          field: 'addresses.state',
          mobileSpecial: 'state',
          label: 'State',
          filterOptions: {
            enabled: true,
            placeholder: 'State',
            filterValue: '',
          },
          sortable: false,
        },
        {
          field: 'addresses.postcode',
          label: 'Zip/Post code',
          mobileSpecial: 'zipCode',
          filterOptions: {
            enabled: true,
            placeholder: 'Zip/Post code',
            filterValue: '',
          },
          sortable: false,
        },
        {
          field: 'addresses.country.name',
          label: 'Country',
          mobileSpecial: 'country',
          filterOptions: {
            enabled: true,
            placeholder: 'Country',
            filterValue: '',
          },
        },
        {
          field: 'telephone',
          label: 'Phone Number',
          filterOptions: {
            enabled: true,
            placeholder: 'Phone Number',
            filterValue: '',
          },
          sortable: false,
        },
        {
          field: 'email',
          label: 'Email',
          filterOptions: {
            enabled: true,
            placeholder: 'Email',
            filterValue: '',
          },
        },
        {
          field: 'is_active',
          label: 'Status',
          filterOptions: {
            enabled: true,
            placeholder: 'Status',
            filterValue: '',
          },
          sortable: false,
        },
        {
          field: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'action-th-width',
          hidden: !this.$can('update', 'users'),
        },
      ],
    }
  },
  setup() {
    const companyName = ref('')
    const companyId = ref('')

    watch(companyName, companyId, () => {
      if (this.$route.query.companyId) {
        const company = this.getCompany(this.$route.query.companyId)
        this.companyId = this.$route.query.companyId
        this.companyName = company.name
      } else {
        this.companyName = ''
      }
    })
    return {
      companyName: '',
    }
  },
  async mounted() {
    if (this.$route.query.companyId) {
      const company = await this.getCompany(this.$route.query.companyId)
      this.companyName = company.name
    }
  },

  computed: {
    ...mapState('companyManagement', {
      items: 'items',
      tableTypes: 'tableTypes',
      statuses: 'statuses',
      pagination: 'pagination',
      searchFields: 'searchFields',
      orderBy: 'orderBy',
      sortedBy: 'sortedBy',
    }),
    page() {
      return this.$route.query?.params || {}
    },
  },
  watch: {
    // // eslint-disable-next-line no-unused-vars
    async $route(to) {
      const additionalPathname = to.fullPath.replace('/company-management/list', '')
      if (!additionalPathname) {
        await this.clearAllData()
        this.companyName = ''
      }
    },
    recordToDelete(val) {
      if (val.id) {
        this.$refs.modalDelete.show()
      } else {
        this.$refs.modalDelete.hide()
      }
    },
    items() {
      if (this.items.length > 0) {
        this.fields = this.fields.map(item => {
          if (item.label === 'Type') {
            // eslint-disable-next-line no-param-reassign
            item = { ...item, filterOptions: { ...item.filterOptions, filterDropdownItems: [...this.tableTypes] } }
          }
          return item
        })
      }
      this.fields = this.fields.map(item => {
        if (item.label === 'Status') {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, filterOptions: { ...item.filterOptions, filterDropdownItems: [...this.statuses] } }
        }
        return item
      })
    },
  },
  methods: {
    async deleteHandler(id, isPermanent = false) {
      this.recordToDelete = {
        id,
        isPermanent,
      }
    },
    async submitDeleteBulk(iSent) {
      if (iSent) {
        await axios.delete('/company/records', {
          params: {
            ids: this.$refs.VueGoodTable.selectedRows.map(item => item.id),
          },
        }).catch(error => {
          console.log('err', error)
          const { status } = error.response
          console.log('status', status)

          if (status === 422) {
            let detailContent = ''

            const companyName = error.response.data.fields.company_name

            if (error?.response?.data?.fields) {
              detailContent += `You cannot remove ${companyName} company because one of the entities has the following relation(s):<br>\n`
              Object.keys(error.response.data.fields).forEach(field => {
                if (field === 'company_name') return
                detailContent += ` - ${field}: \n 
                <ul class="mb-1">
                  ${error.response.data.fields[field].map(item => `<li>${item}</li>`).join('')}
                  </ul>`
              })
            }

            this.showModal({
              title: 'Validation error',
              content: detailContent,
              icon: 'BellIcon',
              variant: 'danger',
            })

            this.deleteErrorPopUpInfo = {
              title: 'Validation error',
              content: detailContent,
              icon: 'BellIcon',
              variant: 'danger',
            }
            return
          }

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else {
            const { message } = error.response.data

            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'BellIcon',
                variant: 'danger',
                message,
              },
            })
          }
        })
      }
      this.fetchItems()
      this.$refs.modalDeleteBulk.hide()
    },
    handleReset() {
      this.resetFilter()
      this.searchValue = ''
    },
    async handleMobileSearch(search, searchValue) {
      if (this.search !== searchValue) {
        this.searchValue = searchValue
      }

      this.columnFilterFn(search, true)
    },
    async deleteModalHandler() {
      const errorRes = await this.deleteRow(this.recordToDelete.id)
      this.deleteErrorPopUpInfo = errorRes
      this.recordToDelete = {
        id: null,
        isPermanent: false,
      }
    },
    redirectToSummary(id) {
      // without setTimeout we will have scroll on table because dropdown can`t handle @hide event
      setTimeout(() => {
        this.$router.push(`summary/${id}`)
      }, 1)
    },
    ...mapActions('companyManagement', {
      fetchItems: 'getItems',
      deleteRecord: 'deleteItem',
      setSearchFields: 'setSearchFields',
      setOrderBy: 'setOrderBy',
      setSortedBy: 'setSortedBy',
      changeItemsStatuses: 'changeItemsStatuses',
      getCompany: 'getCompany',
      recoverRecord: 'recoverItem',
    }),
    closeModal() {
      this.$refs.modal.hide()
    },
    async getData() {
      const {
        pageNum, perPage, search, searchFields, orderBy, sortedBy, companyId,
      } = this.$route.query
      this.page = pageNum ?? 1
      this.perPage = perPage ?? 25
      this.companyId = companyId ?? 0

      const params = {
        ...this.$route.query,
      }

      Object.keys(params).forEach(k => (params[k] === '' || !params[k]) && delete params[k])

      if (search || searchFields || orderBy || sortedBy) {
        await this.fetchtableData({
          ...params,
        })
      } else {
        await this.fetchtableData({
          pageNum: this.page, perPage: this.perPage, companyId: this.companyId,
        })
      }
      this.setSearchParams(search, searchFields)
      this.setOrderBy(orderBy)
      this.setSortedBy(sortedBy)
    },
    async submitStatusesChanging(activeStatus) {
      try {
        await this.changeItemsStatuses({ ids: this.$refs.VueGoodTable.selectedRows.map(item => item.id), is_active: activeStatus })
        await this.getData()
        this.$refs.modal.hide()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    ...mapMutations('hardwareTypes', ['RESET_STATE']),
    rowStyleClassFn(row) {
      return row.is_deleted ? 'deleted-class' : ''
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .table-selected-row-info-panel{
    display: flex;
    align-items: center;
    padding: 10px;
    a{
      color: red
    }
  }
  .vgt-pull-right{
    float: unset !important;
    margin-left: auto;
  }
  .dark-layout{
    .vgt-select{
      background-color: #283046;
      border-color: #404656;
    }
    .table-selected-row-info-panel{
      background-color: #283046;
      border-color: #404656;
    }
  }
  .company-table {
    .deleted-class {
      .vgt-checkbox-col {
        background-color: rgba(255, 0, 0, 0.185) !important;
        input {
          display: none;
        }
      }
      .vgt-left-align {
        background-color: rgba(255, 0, 0, 0.185) !important;
      }
    }
  }
</style>
